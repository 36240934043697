export default function bannerAnchor(){
    if(document.querySelector('.main-banner__indicator')){
        let buttonIndicator = document.querySelector('.main-banner__indicator');
        let vhPixels = window.innerHeight * 0.90;
    
        buttonIndicator.addEventListener('click', () => {
            window.scrollTo({
                top: vhPixels,
                behavior: 'smooth',
            });
        });
    }
}
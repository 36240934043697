export default function rangeValue(){
    if (document.querySelector('#valueRange') && document.querySelector('#plotsRange')){
        let valueRange = document.querySelector('#valueRange');
        let plotsRange = document.querySelector('#plotsRange');
        let outputValue = document.querySelector('.output-value');
        let outputPlots = document.querySelector('.output-plots');

        valueRange.addEventListener('input', () => {
            let inputValue = valueRange.value;

            outputValue.textContent = inputValue + ".000";
        });
        plotsRange.addEventListener('input', () => {
            let inputValue = plotsRange.value;

            outputPlots.textContent = inputValue + "x";
        });
    }
}